/* Kontener zawierający metody płatności */
.Wo4qW {
    display: flex!important;
}

/* Przelewy24 na pierwszym miejscu */
.Wo4qW .B4zH6:nth-child(5) {
    order: 1;
}

/* Klarna na drugim miejscu */
.Wo4qW .B4zH6:nth-child(4) {
    order: 2;
}

/* Viva Wallet na trzecim miejscu */
.Wo4qW .B4zH6:nth-child(3) {
    order: 3;
}

/* Karta kredytowa na czwartym miejscu */
.Wo4qW .B4zH6:nth-child(1) {
    order: 4;
}

/* PayPal na piątym miejscu */
.Wo4qW .B4zH6:nth-child(2) {
    order: 5;
}
